"use client";

import { FC, useState } from "react";
import axios from "axios";
import Rating from "@mui/material/Rating";

import { useStyles } from "./styles";
import { UserRating } from "@/types/social";
import { useAppDispatch } from "@/redux/store";
import { generateAlert } from "@/redux/actions";
import { getDisplayNumber } from "@/utils/number";

interface QuizRatingProps {
  slug?: string;
  userRating?: UserRating | null;
  overallRating?: number | null;
  ratingCount?: number;
  size?: string;
}

const QuizRating: FC<QuizRatingProps> = ({
  slug,
  userRating,
  overallRating,
  ratingCount,
  size = "large",
}) => {
  const { classes } = useStyles({ size });
  const dispatch = useAppDispatch();
  const [rating, setRating] = useState(
    overallRating || userRating?.starLevel || 0
  );
  const [loading, setLoading] = useState(false);
  const [localUserRating, setLocalUserRating] = useState<
    UserRating | null | undefined
  >(userRating);

  const handleRatingChange = (event: any, newValue: number | null) => {
    handleUpdateRating(rating, newValue || 0);
  };

  const handleUpdateRating = async (existingRating: number, rating: number) => {
    if (loading || !slug) return;

    try {
      setLoading(true);
      setRating(rating);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      if (localUserRating) {
        // Update existing rating
        if (rating) {
          const reqData = { starLevel: rating };

          const response = await axios.patch(
            `${process.env.NEXT_PUBLIC_API_URL}/rating/quiz/${localUserRating.id}`,
            reqData,
            config
          );
          setLocalUserRating(response.data);
          dispatch(
            generateAlert({
              text: "Your rating has been saved!",
              type: "success",
            })
          );
        } else {
          // Delete rating
          await axios.delete(
            `${process.env.NEXT_PUBLIC_API_URL}/rating/quiz/${localUserRating.id}`,
            config
          );
          setLocalUserRating(null);

          dispatch(
            generateAlert({
              text: "Your rating has been removed!",
              type: "success",
            })
          );
        }
      } else {
        // Create new rating
        const reqData = { starLevel: rating, slug };

        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/rating/quiz`,
          reqData,
          config
        );
        setLocalUserRating(response.data);
        dispatch(
          generateAlert({
            text: "Your rating has been saved!",
            type: "success",
          })
        );
      }
    } catch (error: unknown) {
      console.error(error);
      setRating(existingRating);
    }
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      {overallRating === undefined && (
        <p className={classes.label}>
          {rating ? "Your Rating" : "Rate this Quiz!"}
        </p>
      )}
      <div className={classes.ratingRow}>
        <Rating
          value={rating}
          onChange={handleRatingChange}
          className={classes.rating}
          precision={overallRating !== undefined ? 0.5 : 1}
          readOnly={overallRating !== undefined || loading}
        />
        {ratingCount && ratingCount > 0 && (
          <p className={classes.ratingCount}>{getDisplayNumber(ratingCount)}</p>
        )}
      </div>
    </div>
  );
};

export default QuizRating;
