"use client";

import { FC } from "react";
import Link from "next/link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import dynamic from "next/dynamic";

import { useStyles } from "./styles";
import { Explore, ContentType } from "@/types/explore";
import { TransformationType } from "@/types/thread";
import { useAppDispatch } from "@/redux/store";
import { setRoomUUID, setSelectedGeneration } from "@/redux/actions";
import { isValidUrl } from "@/utils/url";
import useSafetyFilter from "@/hooks/useSafetyFilter";
import Image from "@/components/common/Image";
import Controls from "./Controls";
import ExpandableText from "@/components/common/ExpandableText";
import QuizRating from "@/components/quiz/common/QuizRating";

const UserDetails = dynamic(() => import("@/components/profile/UserDetails"));

function getLabel(type: ContentType, modelUsed?: string) {
  if (type === "image") {
    if (modelUsed === TransformationType.HD) return "HD";
    if (modelUsed === TransformationType.Flux_Pro) return "HDR+";
    if (modelUsed === TransformationType.Remix) return "REMIX";
    if (modelUsed === TransformationType.Enhance) return "ENHANCED";
  }
  if (type === "quiz") {
    return "QUIZ";
  }
  return "";
}

interface FeedCardProps {
  item: Explore;
  hideUserDetails?: boolean;
}

const FeedCard: FC<FeedCardProps> = ({ item, hideUserDetails = false }) => {
  const label = getLabel(item.type, item.meta.modelUsed);
  const { classes, cx } = useStyles({ label });
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isUnsafe = item.safetyRating === 1;
  const blurImage = useSafetyFilter(isUnsafe);
  const thumbnail = isValidUrl(item.imageUrl)
    ? item.imageUrl
    : "https://files.idyllic.app/files/static/idyllic-logo.png";

  const handleImageClick = () => {
    if (item.type === "image") {
      dispatch(setRoomUUID(null));
      if (item.meta.generationId) {
        dispatch(setSelectedGeneration(item.meta.generationId));
      }
    }
  };

  return (
    <div className={classes.container}>
      <Link
        href={item.path}
        onClick={handleImageClick}
        className={classes.link}
        prefetch={false}
      >
        <div
          className={cx(
            label ? classes.imageWrapperWithLabel : classes.imageWrapper
          )}
        >
          <Image
            src={thumbnail}
            alt={item.description || ""}
            width={isMobile ? 140 : 200}
            height={isMobile ? 140 : 200}
            isUnsafe={isUnsafe}
            className={classes.image}
          />
          {item.type === "quiz" && (
            <div className={classes.textOverlayContainer}>
              <p className={classes.textOverlay}>{item.description}</p>
            </div>
          )}
          <Controls item={item} isUnsafe={isUnsafe} blurImage={blurImage} />
          {label && <p className={classes.label}>{label}</p>}
        </div>
      </Link>
      {item.type === "image" && (
        <ExpandableText
          text={item.description}
          limit={isMobile ? 25 : 50}
          className={classes.title}
        />
      )}
      {Boolean(item.meta.starRating) && (
        <div className={classes.starRating}>
          <QuizRating size="medium" overallRating={item.meta.starRating} />
        </div>
      )}
      {!hideUserDetails && (
        <div className={classes.userDetails}>
          <UserDetails
            username={item.username}
            avatarUrl={item.avatarUrl}
            showAvatar
            subscriptionType={item.subscriptionType}
            orientation="vertical"
            avatarSize="small"
          />
        </div>
      )}
    </div>
  );
};

export default FeedCard;
