import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{
  color?: string;
  variant: "button" | "icon";
}>()((theme, { color, variant }) => ({
  icon: {
    color: color || theme.palette.primary.main,
    filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
    transition: "background-color 0.1s, transform 0.1s",
    "&:hover": {
      transform: variant === "button" ? "scale(1.1)" : "none",
    },
  },
}));
