"use client";

import { FC, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import { MdContentCopy } from "react-icons/md";

import { useStyles } from "./styles";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { generateAlert } from "@/redux/actions";

async function logInteraction() {
  try {
    await fetch(`${process.env.NEXT_PUBLIC_API_URL}/stats/image-copy`, {
      method: "POST",
      credentials: "include",
    });
  } catch (error: unknown) {
    let errorMessage = "Failed to log image copy interaction";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error(errorMessage);
  }
}

interface CopyImageProps {
  imageUrl: string;
  color?: string;
}

const CopyImage: FC<CopyImageProps> = ({ imageUrl, color }) => {
  const { classes } = useStyles({ color });
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const createImage = (url: string) => {
    const img = Image ? new Image() : document.createElement("img");
    img.src = url;
    return img;
  };

  function convertToPng(imgBlob: Blob) {
    const imageUrl = window.URL.createObjectURL(imgBlob);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageEl = createImage(imageUrl);
    imageEl.onload = (e: Event) => {
      const target = e.target as HTMLImageElement;
      if (ctx && target) {
        canvas.width = target.width;
        canvas.height = target.height;
        ctx.drawImage(target, 0, 0, target.width, target.height);
        canvas.toBlob(copyToClipboard, "image/png", 1);
      }
    };
  }

  async function copyToClipboard(blob: Blob | null) {
    try {
      if (!blob) return;
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      await logInteraction();
      // if (!isSubscribed && imageUrl.includes("watermark")) {
      //   dispatch(
      //     addAlertDialog({
      //       title: "Premium Benefits",
      //       description:
      //         "Upgrade to premium today to remove watermarks from images!",
      //       prevButtonText: "Close",
      //       nextButtonText: "Upgrade",
      //       type: AlertDialogType.SubscriptionRequired,
      //     })
      //   );
      // }
      dispatch(
        generateAlert({ text: "Image copied to clipboard", type: "success" })
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCopy(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    const img = await fetch(imageUrl);
    const imgBlob = await img.blob();
    convertToPng(imgBlob);
  }

  return (
    <IconButton
      className={classes.icon}
      onClick={handleCopy}
      aria-label="Copy Image"
    >
      <MdContentCopy />
    </IconButton>
  );
};

export default CopyImage;
