"use client";

import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

import { Explore } from "@/types/explore";
import debounce from "@/utils/debounce";

async function fetchSearchResults(
  searchInput: string
): Promise<Explore[] | string> {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/explore/search?prompt=${searchInput}`,
      {
        withCredentials: true,
      }
    );
    return response.data.items;
  } catch (error) {
    console.error(error);
    return "Failed to fetch search results";
  }
}

const useExploreSearch = (
  searchInput: string,
  loadResults = false,
  delay = 0
) => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Explore[]>([]);
  const latestSearchInput = useRef(searchInput);

  const handleUpdateSearchResults = (searchResults: Explore[]) => {
    if (latestSearchInput?.current === "") {
      setSearchResults([]);
      return;
    }
    setSearchResults(searchResults);
  };

  const debouncedSearch = useCallback(
    debounce(async (input: string) => {
      setLoading(true);
      const results = await fetchSearchResults(input);
      if (latestSearchInput.current === input && typeof results !== "string") {
        handleUpdateSearchResults(results);
      }
      setLoading(false);
    }, delay),
    []
  );

  useEffect(() => {
    latestSearchInput.current = searchInput;
    if (searchInput !== "" && searchInput.length > 2 && loadResults) {
      debouncedSearch(searchInput);
    } else {
      setSearchResults([]);
    }
  }, [searchInput, loadResults]);

  return { loading, searchResults };
};

export default useExploreSearch;
