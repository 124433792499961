"use client";

import { FC } from "react";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

import { useStyles } from "./styles";
import { Explore } from "@/types/explore";
import CopyImage from "@/components/common/CopyImage";
import InpaintButton from "@/components/gen/InpaintButton";
import RatingControls from "@/components/social/ratings/RatingControls";

interface ControlsProps {
  item: Explore;
  isUnsafe: boolean;
  blurImage: boolean;
}

const Controls: FC<ControlsProps> = ({ item, isUnsafe, blurImage }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {item.type === "image" && (
        <>
          {!blurImage && <InpaintButton url={item.imageUrl} color="white" />}
          {!isUnsafe && <CopyImage imageUrl={item.imageUrl} color="white" />}
          {!blurImage && item.meta.imageId && (
            <RatingControls
              imageId={item.meta.imageId}
              likesCount={item.likesCount || 0}
              userRating={item.userRating || null}
              hideLikesCount
            />
          )}
        </>
      )}
      {Boolean(item.meta.plays) && (
        <div className={classes.plays}>
          <p>{item.meta.plays}</p>
          <GroupOutlinedIcon />
        </div>
      )}
    </div>
  );
};

export default Controls;
