import {
  FC,
  ReactNode,
  CSSProperties,
  Children,
  isValidElement,
  ReactElement,
} from "react";

interface MasonryProps {
  children: ReactNode;
  columnsCount?: number;
  gutter?: string | number;
  gutterX?: string | number;
  gutterY?: string | number;
  className?: string;
  style?: CSSProperties;
}

const Masonry: FC<MasonryProps> = ({
  children,
  columnsCount = 3,
  gutter = 16,
  gutterX = gutter,
  gutterY = gutter,
  className,
  style,
}) => {
  const getColumns = () => {
    const columns: ReactElement[][] = Array.from(
      { length: columnsCount },
      () => []
    );
    let validIndex = 0;
    Children.forEach(children, (child) => {
      if (child && isValidElement(child)) {
        columns[validIndex % columnsCount].push(child);
        validIndex++;
      }
    });
    return columns;
  };

  const renderColumns = () => {
    return getColumns().map((column, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "stretch",
          flex: 1,
          width: 0,
          columnGap: gutterX,
          rowGap: gutterY,
        }}
      >
        {column.map((item) => item)}
      </div>
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "stretch",
        boxSizing: "border-box",
        width: "100%",
        gap: gutter,
        ...style,
      }}
      className={className}
    >
      {renderColumns()}
    </div>
  );
};

export default Masonry;
