export const formatDisplayTotal = (total: number) => {
  if (total > 10000) {
    return Math.floor(total / 1000) + "k";
  }
  if (total >= 1000) {
    return (total / 1000).toFixed(1) + "k";
  } else {
    return total;
  }
};
