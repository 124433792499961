import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{
  userRated: boolean;
}>()((theme, { userRated }) => ({
  container: {
    position: "relative",
    zIndex: 10,
    opacity: 1,
    cursor: "pointer",
  },
  loveIcon: {
    cursor: "pointer",
    filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
    transition: "background-color 0.1s, transform 0.1s",
    "&:hover": {
      transform: "scale(1.1)",
    },
    fontSize: "calc(1vw + 1vh + 0.5vmin)",
    color: userRated ? theme.palette.secondary.main : "#ffffff",
  },
  totalRatings: {
    textShadow: "1px 1px 2px #000000",
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    position: "absolute",
    fontSize: "0.8rem",
    color: userRated ? theme.palette.secondary.main : "#ffffff",
  },
}));
