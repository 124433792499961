import React, { useState } from "react";

interface ExpandableTextProps {
  text: string;
  limit: number;
  className?: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  limit,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = text && text.length > limit;
  const displayText =
    isExpanded || !shouldTruncate
      ? text
      : `${text.substring(0, limit).trim()}...`;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p
      className={className || ""}
      onClick={toggleExpand}
      style={{ cursor: "pointer" }}
    >
      {displayText}
    </p>
  );
};

export default ExpandableText;
