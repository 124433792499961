"use client";

import { FC, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import { TbLassoPolygon } from "react-icons/tb";

import { useStyles } from "./styles";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";

interface InpaintButtonProps {
  url: string;
  variant?: "button" | "icon";
  color?: string;
  className?: string;
}

const InpaintButton: FC<InpaintButtonProps> = ({
  url,
  variant = "button",
  color,
  className,
}) => {
  const { classes, cx } = useStyles({ variant, color });
  const dispatch = useAppDispatch();
  const { dispatch: genDispatch } = useGenContext();
  const user = useAppSelector((state) => state.user.user);
  const quotas = useAppSelector((state) => state.user.quotas);
  const inpaint = quotas.find((quota) => quota.resourceName === "inPaint");
  const dailyInpaintUsed = inpaint ? (inpaint.currentQuota ?? 0) <= 0 : false;

  const handleInpaint = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!user) {
      dispatch(
        addAlertDialog({
          title: "Account Required",
          description:
            "Please sign in or create a free account to edit this image",
          prevButtonText: "Sign In",
          nextButtonText: "Create Account",
          type: AlertDialogType.AccountRequired,
        })
      );
      return;
    }

    if (dailyInpaintUsed) {
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "Upgrade to continue using Replace!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
      return;
    }

    genDispatch({
      type: "SET_SELECTED_IMAGE",
      payload: url,
    });
    genDispatch({ type: "SET_IS_EDITING_MASK", payload: true });
    genDispatch({ type: "SET_STROKES", payload: [] });
    genDispatch({ type: "SET_IMAGES", payload: [] });
  };

  return (
    <IconButton
      onClick={handleInpaint}
      className={cx(classes.icon, className)}
      aria-label="Replace Area"
    >
      <TbLassoPolygon />
    </IconButton>
  );
};

export default InpaintButton;
