import { makeStyles } from "tss-react/mui";

function getWrapperBackground(label: string) {
  switch (label) {
    case "HD":
    case "HDR+": {
      return "linear-gradient(45deg, rgba(255, 215, 0, 1), rgba(218, 165, 32, 0.8), rgba(255, 223, 0, 0.9), rgba(218, 165, 32, 0.8), rgba(255, 215, 0, 0.8))";
    }
    case "REMIX": {
      return "linear-gradient(45deg, rgba(139, 0, 0, 1), rgba(94, 15, 15, 0.8), rgba(165, 42, 42, 0.9), rgba(94, 15, 15, 0.8), rgba(139, 0, 0, 0.8))";
    }
    case "ENHANCED": {
      return "linear-gradient(45deg, rgba(0, 0, 139, 1), rgba(15, 15, 94, 0.8), rgba(42, 42, 165, 0.9), rgba(15, 15, 94, 0.8), rgba(0, 0, 139, 0.8))";
    }
    case "QUIZ": {
      return "linear-gradient(45deg, rgba(135, 41, 229, 1), rgba(110, 35, 186, 0.8), rgba(140, 48, 246, 0.9), rgba(110, 35, 186, 0.8), rgba(135, 41, 229, 0.8))";
    }
    default:
      return "";
  }
}

export const useStyles = makeStyles<{ label: string }, "title">()(
  (theme, { label }, classes) => ({
    container: {
      width: "100%",
      display: "inline-flex",
      flexDirection: "column",
      position: "relative",
      marginBottom: theme.spacing(2),
      "&:hover": {
        [`& .${classes.title}`]: {
          color: theme.palette.text.secondary,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
        marginBottom: 0,
      },
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
    imageWrapper: {
      display: "block",
      position: "relative",
      marginBottom: theme.spacing(2),
      "&:hover": {
        filter: "brightness(90%)",
        transform: "scale(1.025)",
        zIndex: 0,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    imageWrapperWithLabel: {
      display: "block",
      position: "relative",
      padding: theme.spacing(1),
      borderRadius: "15px",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      backgroundImage: getWrapperBackground(label),
      "&:hover": {
        filter: "brightness(90%)",
        transform: "scale(1.025)",
        zIndex: 0,
      },
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "15px",
      transition: "transform 0.3s ease, filter 0.1s ease",
      cursor: "pointer",
      marginBottom: label ? theme.spacing(3.5) : theme.spacing(1),
      backgroundColor: "#ececec",
      [theme.breakpoints.down("md")]: {
        borderRadius: "10px",
      },
    },
    textOverlayContainer: {
      width: "calc(100% - 8px)",
      transform: "translateX(4px)",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 22,
      left: 0,
      color: "white",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
      fontSize: "0.8rem",
      fontWeight: 700,
      borderRadius: "0 0 15px 15px",
      padding: theme.spacing(1, 0),
    },
    textOverlay: {
      maxWidth: "95%",
    },
    label: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: 1,
      width: "100%",
      fontFamily: "montserrat, sans-serif",
      fontWeight: 700,
      color: ["HD", "HDR+"].includes(label)
        ? theme.palette.primary.main
        : "white",
      border: "none",
    },
    title: {
      fontSize: "0.8rem !important",
      marginBottom: theme.spacing(2),
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
      },
    },
    starRating: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
    userDetails: {
      display: "flex",
      width: "100%",
    },
  })
);
