"use client";

import { FC, useState, useEffect, MouseEvent } from "react";
import { useRouter } from "next/navigation";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { useStyles } from "./styles";
import { Nullable } from "@/types/common";
import { UserRating } from "@/types/social";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { createImageRating, deleteRating } from "@/redux/actions";
import { formatDisplayTotal } from "./helpers";

interface RatingControlsProps {
  likesCount: number;
  userRating: Nullable<UserRating>;
  imageId: number;
  className?: string;
  hideLikesCount?: boolean;
}

const RatingControls: FC<RatingControlsProps> = ({
  likesCount,
  userRating,
  imageId,
  className = "",
  hideLikesCount = false,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [userRated, setUserRated] = useState(Boolean(userRating));
  const [localLikesCount, setLocalLikesCount] = useState(likesCount);
  const [localUserRating, setlocalUserRating] = useState(userRating);
  const [loading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user.user);
  const { classes, cx } = useStyles({
    userRated,
  });

  const handleVote = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      if (!user) {
        router.push("/register");
        return;
      }
      const newUserRated = !userRated;
      setUserRated(newUserRated);
      if (newUserRated) {
        setLocalLikesCount((prevValue) => prevValue + 1);

        const reduxResult: any = await dispatch(createImageRating(imageId));
        if (reduxResult?.payload) {
          setlocalUserRating(reduxResult.payload);
        } else {
          setLocalLikesCount((prevValue) => prevValue - 1);
          setUserRated(!newUserRated);
        }
      } else if (!newUserRated && localUserRating) {
        setLocalLikesCount((prevValue) => prevValue - 1);

        const reduxResult: any = await dispatch(
          deleteRating(localUserRating.id || 0)
        );
        if (reduxResult) {
          setlocalUserRating(null);
        } else {
          setLocalLikesCount((prevValue) => prevValue + 1);
          setUserRated(!newUserRated);
        }
      }
    }
  };

  useEffect(() => {
    if (loading) {
      let ratingInterval: Nullable<ReturnType<typeof setInterval>> =
        setInterval(() => {
          setLoading(false);
        }, 500);

      return () => {
        if (ratingInterval) {
          clearInterval(ratingInterval);
          ratingInterval = null;
        }
      };
    }
  }, [loading]);

  return (
    <div className={cx(classes.container, className)}>
      <IconButton
        className={classes.loveIcon}
        onClick={handleVote}
        aria-label="Toggle like"
      >
        {userRated ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>

      {!hideLikesCount && (
        <Typography variant="body2" className={classes.totalRatings}>
          {formatDisplayTotal(localLikesCount)}
        </Typography>
      )}
    </div>
  );
};

export default RatingControls;
