"use client";

import {
  ReactNode,
  ReactElement,
  CSSProperties,
  Children,
  useMemo,
  cloneElement,
  isValidElement,
} from "react";
import useWindowWidth from "@/hooks/useWindowWidth";

const DEFAULT_COLUMNS_COUNT = 1;

interface ResponsiveMasonryProps {
  columnsCountBreakpoints: Record<string, number>;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const ResponsiveMasonry = ({
  columnsCountBreakpoints,
  children,
  className,
  style,
}: ResponsiveMasonryProps) => {
  const windowWidth = useWindowWidth() || 0;

  const columnsCount = useMemo(() => {
    const breakpoints = Object.keys(columnsCountBreakpoints)
      .map((key) => parseInt(key, 10))
      .filter((bp) => !isNaN(bp))
      .sort((a, b) => a - b);

    let count =
      breakpoints.length > 0
        ? columnsCountBreakpoints[breakpoints[0].toString()]
        : DEFAULT_COLUMNS_COUNT;

    breakpoints.forEach((breakpoint) => {
      if (breakpoint < windowWidth) {
        count = columnsCountBreakpoints[breakpoint.toString()];
      }
    });

    return count;
  }, [windowWidth, columnsCountBreakpoints]);

  if (!children) return null;

  return (
    <div className={className} style={style}>
      {Children.map(children, (child, index) =>
        isValidElement(child)
          ? cloneElement(child as ReactElement, {
              key: index,
              columnsCount: columnsCount,
            })
          : child
      )}
    </div>
  );
};

export default ResponsiveMasonry;
