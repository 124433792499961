import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  plays: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: "white",
    filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
    fontSize: "0.8rem",
    fontWeight: 700,
  },
}));
