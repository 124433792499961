import { makeStyles } from "tss-react/mui";

const getStarSize = (size: string) => {
  if (size === "small") return 12;
  if (size === "medium") return 18;
  if (size === "large") return 20;
  return size;
};

export const useStyles = makeStyles<{ size: string }>()((theme, { size }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  ratingRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  label: {
    fontSize: "1.13rem",
    fontWeight: 500,
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.88rem",
    },
  },
  rating: {
    fontSize: getStarSize(size),
  },
  ratingCount: {
    fontSize: "0.63rem",
    fontWeight: 500,
    color: "#767676",
  },
}));
